// Here you can add other styles
.c-wrapper:not(.c-wrapper-fluid) .c-header-fixed {
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  z-index: 999999999;
  height: 126px;
  display: flex;
  align-items: center;
  // background:linear-gradient(to right, #84cec2 , #77c479);
  // padding:40px 50px;
  background-image: url(../images/aurora-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
:root{
  --buttom_color:#bf73f3;
  --button_hover_color:#cc8ff5;
  --focus_color:#a061cc;
  --primery_disable_btn:#e7cafa;
  --secondary_btn-color:#0f5238;
  --sec_hover_color:#0a3826;
  --sec_focus_color:#1d5d45;
  --error_color:#b00020;
  -o-border_color:#bbefe5;
  --white_color:#ffffff;
}
.advice_btn {
  background: #fff;
  border: none;
  color: #477894;
  display: table;
  margin-left: auto;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 20px;
  display: flex;
}

.logo_style {
  max-width: 100%;
}

.right_zero {
  right: 0;
}

p.header_text.ml-2 {
  max-width: 260px;
  margin: 0;
  margin-left: 24px !important;
}

.position-absolute {
  position: absolute !important;
}

.c-wrapper {
  margin-top: 120px;
  min-height: initial;
}

.c-sidebar.c-sidebar-fixed {
  top: 124px;
}

.c-sidebar {
  color: #fff;
  background: #ffffff;
}

.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed li {
  padding: 14px 11px;
  background: #477894;
  list-style: none;
}

.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed ul {
  padding: 0;
}

.c-sidebar li a {
  color: #fff;
  font-size: 14px;
}

body {
  font-family: Open Sans, sans-serif !important;
}

a:hover {
  text-decoration: none;
}

button.advice_btn svg {
  margin-left: 7px;
  margin-top: 4px;
}

.coming_background {
  width: 100%;
  height: 690px;
}

.sign_inform .main-content-container {
  max-width: 550px;
  float: inherit;
}


.color_bluegreen {
  color: #477894;
}

.coming_background {
  width: 100%;
  height: 450px;
  background: white;
}

.grad_btn,
.grad_btn:hover {
  background-image: linear-gradient(90deg, #2a6481, #85c3c5);
  border: none;
  padding: 7px 18px;
  color: #fff;
  border-radius: 30px;
  

}

.col_blu {
  color: #477894;
  font-weight: 600;
}

userda.row {
  padding: 13px;
}

button.submmc.btn.btn-pill {
  background-color: var(--secondary_btn-color);
  /* margin: 0px 20px; */
  width: 55%;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
}

button.deleteapp.btn.btn-pill {
  background-color: var(--error_color);
  /* margin: 0px 20px; */
  width: 40%;
  float: right;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
}

.highlevel {
  font-size: 24px;
  font-weight: bold;
}

.Primaryhead {
  margin: 0px;
  padding: 0px 13px;
  font-size: 15px;
}

img.c-avatar-img.myav {
  padding: 11px;
}

dl.row.aboutuser {
  padding: 9px 0px;
}

.primarycol {
  // background-color: cadetblue;
  
  color: #ffffff;
  padding: 8px 0px;
  margin: 0px 0px 0px 2px;
  max-width: fit-content;
}

.Primaryblock {
  background-color: #365858;
  
  padding: 0px;
}

button.amlstatus.btn.btn-pill {
  color: #ffffff;
  background-color: #429646;
  font-size: 14px;
  font-weight: 600;
}

.status {
  padding: 0px;
  color: #4caf50;
  font-size: 12px;
  font-weight: 500;
}

.userda.row {
  margin: 0;
}

button.submmc.btn.btn-pill {
  margin: 10px;
}

button.deleteapp.btn.btn-pill {
  margin: 10px;
}

button.deleteapp.btn.btn-pill {
  width: 56% !important;
}