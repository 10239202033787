@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Mulish:wght@200;300;400;500;600;700;900&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&family=Poppins:wght@200;300;400;600&family=Roboto:ital,wght@0,400;0,700;1,300;1,400;1,700&family=Teko:wght@300;400;500;600;700&display=swap');

:root{
  --buttom_color:#bf73f3;
  --button_hover_color:#cc8ff5;
  --focus_color:#a061cc;
  --primery_disable_btn:#e7cafa;
  --secondary_btn-color:#0f5238;
  --sec_hover_color:#0a3826;
  --sec_focus_color:#1d5d45;
  --error_color:#b00020;
  -o-border_color:#bbefe5;
  --white_color:#ffffff;
}
body{
  font-family: 'Plus Jakarta Sans';
}

.grad_btn,
.grad_btn:hover {
  background: var(--buttom_color);
  border: none;
  padding: 7px 18px;
  color: #fff;
  border-radius: 30px;

}

.color_bluegreen {
  color: var(--secondary_btn-color) !important;
  font-weight: 600;

}

.min-height-350 {
  min-height: 350px;
}

.min-height-507 {
  min-height: 507px;
}

.chart_value p {
  font-size: 16px;
}

.chart_value h5 {
  font-size: 16px;
  font-weight: 700 !important;
}

.arrow_size {
  max-width: 16px;
  margin-left: 10px;
  margin-top: -3px;
}

.color-white i {
  color: #fff;
}

.coming_background {
  width: 100%;
  height: auto;
}

h4.color_bluegreen.mb-4 {
  font-size: 20px;
}

.image-progress img {
  max-width: 100%;
  margin-bottom: 40px;
}

.createStatement {
  background: url(images/Retirement_plus_strategy-min.jpeg) top no-repeat;
  width: 100%;
  background-size: cover !important;
  background-position: center;
}

.createStatement.invstone {
  background: url(images/conservative_strategy.jpeg) top no-repeat;
  /* background-position:center ; */
  background-size: cover;
  background-position:  center;
}

.createStatement.invsttwo {
  background: url(images/balanced_strategy-min.jpeg) top no-repeat;
  /* background-size: contain cover; */
  /* background-position: top -335px right 0 */
  background-position: center;
}

.createStatement.invstthree {
  background: url(images/Growth_strategy-min.png) top no-repeat;
  background-size: cover;
  /* background-position: top -188px right 0 */
  /* background-position: center center; */
}

.createStatement.invstfour {
  background: url(images/First_home_buyer_strategy-min.jpeg) top no-repeat;
}

.createStatement.invstfive {
  background: url(images/Climate_strategy-min-1.jpg) top no-repeat;
  /* background-position: top -185px right 0 */
}

.createStatement.invstsix {
  background: url(images/Climate_growth_strategy-min.jpeg) top no-repeat;
  /* background-position: top -335px right 0 */
}

.createStatement.invstseven {
  background: url(images/Retirement_plus_strategy.jpeg) top no-repeat;
  background-position: center center;
}
.createStatement.invsteight {
  /* background: url(https://aurora-py-documents.s3.ap-southeast-2.amazonaws.com/Liquidity+Fund.jpeg) */
    /* top no-repeat; */
    background: url(images/Liquidity-Fund.jpg) top no-repeat;
  background-position: center center;
}

.performance_month {
  /* border-bottom: 2px solid #477894; */
  /* border-right: 2px solid #477894; */
  padding: 25px;
}

.performance_section .col-sm-6 {
  padding: 0;
}

.performance_section {
  padding: 15px;
}

.fund_name a,
.fund_name a:hover {
  text-decoration: underline !important;
  color: var(--secondary_btn-color);
}

.border_line {
  border-top: 3px solid #477894;
  padding-top: 10px;
}

.perform_color {
  font-size: 16px;
  font-weight: 600;
}

.list_none li {
  font-weight: 600;
  margin-bottom: 20px;
}

.list_none li a {
  color: #292929;
}

.bold-600 {
  font-weight: 600;
}

.scroll_height {
  height: 80px;
  overflow-y: auto;
}

.nine_values .col-sm-4:nth-last-child(2),
.nine_values .col-sm-4:nth-last-child(3) {
  border-bottom: none;
}

.nine_values .col-sm-4:nth-last-child(1) {
  border: none;
}

.nine_values .col-sm-4:nth-child(3),
.nine_values .col-sm-4:nth-child(6) {
  border-right: none;
}

.nine_values .col-sm-4 {
  border-bottom: 2px solid #477894;
  border-right: 2px solid #477894;
  padding: 20px;
}

span.log_btn i {
  margin-top: -3px;
  margin-left: 12px;
  font-size: 20px !important;
}

.addr_width_set {
  line-height: 30px;
}

p.login_error {
  color: red;
}

.sign_inform .main-content-container {
  max-width: 550px;
  float: inherit;
}

p.min-scroll {
  height: 120px;
  overflow-y: auto;
}

.ant-col.ant-form-item-label label,
.ant-form label,
.modal-body label,
input.ant-input {
  font-size: 14px !important;
}

.modal-body input.ant-input,
.pir_border,
:is(.date_width) :is(input#date, input#expiryDate),
input.pac-target-input {
  border: none;
  padding: 5px;
  border-radius: 0 !important;
  border-bottom: 1px solid #a4a4a4;
}

select#select_option1 {
  padding: 5px;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #a4a4a4 !important;
}

.date_width {
  width: 60%;
  float: right;
}

.pir_border.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  padding: 5px;
}

.fillwidth .ant-form-item-control-input {
  min-height: 0;
}

.modal-body {
  max-height: 390px;
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-btn-primary {
  color: #fff;
  background: var(--secondary_btn-color) !important;
  border-color: var(--secondary_btn-color) !important;
  border-radius: 32px !important;
}

.ant-btn-primary span {
  color: #fff;
}

span.MuiButton-label {
  font-size: 14px !important;
  text-transform: capitalize;
}

.aml_fail {
  color: var(--error_color);
}

.aml_pass {
  color: green;
}

.loader_center {
  z-index: 99999999999999999999;
  width: 100%;
  background: #ebedef;
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
}

.loader_center img {
  position: absolute;
  z-index: 1;
  max-width: 160px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: loadingH 1.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

@keyframes loadingH {
  0% {
    width: 120px;
  }
  50% {
    width: 170px;
    padding: 4px;
  }
  100% {
    width: 120px;
  }
}

tr.error_table th {
  width: 1% !important;
}

.none_display {
  display: none;
}

.custom_datepiker .clndr-control .left,
.custom_datepiker .clndr-control .right {
  padding-left: 0 !important;
  margin-left: -8px !important;
  margin-top: -6px !important;
}

.custom_datepiker .ui.compact.icon.button,
.custom_datepiker .ui.compact.icon.buttons .button {
  padding: 10px 2px !important;
}

button.advice_btn svg {
  margin-top: -3px;
}

.ant-btn-round {
  height: 34px !important;
}

.eye_icon {
  color: #477894;
}

.btn-rmv2 {
  max-width: 25px;
  border: none;
  background: var(--error_color);
  color: #fff;
  margin-left: 10px;
}

span#bold {
  color: #fff;
  background: #477894;
  padding: 3px 19px;
  border-radius: 20px;
}

.faq-box button {
  border: 0;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  color: #1F1F1F;
  width: 100%;
  text-align: left;
  /* text-transform: uppercase; */
}

button.grad_btn {
  color: #fff;
}

li.color-active button {
  color: var(--buttom_color);
  text-align: left;
  width: 100%;
}

img.btn_icon {
  position: absolute;
  bottom: 62px;
  right: 1px;
}

.weight {
  font-size: 14px;
}

.size {
  font-size: 22px;
}

.sb-avatar__text {
  background: #fff !important;
}

.avatarname span {
  color: var(--secondary_btn-color);
}

a.mysidebar.avatarid {
  padding: 5px;
  display: block;
  width: 165px;
}

.user_profile.profile {
  margin: 14px 20px;
  padding: 9px;
}

.performance_month {
  padding: 25px 10px;
}

a.mysidebar {
  padding: 10px 15px;
}

a.mysidebar.current {
  background: #477894;
  color: #fff;
}

.logo-width {
  width: 150px;
}

.logo-width img {
  max-width: 100%;
}

.pir_border span.anticon.anticon-down.ant-select-suffix {
  top: 5px;
  position: relative;
}

.pir_border span.ant-select-selection-item {
  align-items: center;
  display: flex;
}

.faq-box .risk_box {
  box-shadow: 0 0 10px #ccc;
  padding: 30px;
  margin-top: 30px;
}

.faq-box .risk-data.mb-3.mt-4 {
  margin: 0 !important;
}

.zero-position {
  padding: 0 !important;
}

.back-opect {
  background: #00000073;
}

.swal2-styled.swal2-confirm {
  border-radius: 0.25em !important;
  padding: 0.625em 1.1em !important;
}

.c-footer {
  position: fixed;
  bottom: 0;
  width: calc(100% - 256px);
  right: 0;
}

.c-body {
  margin-bottom: 50px;
}

.custom_set {
  border: none;
  border-bottom: 1px solid #a4a4a4;
}

.contrycode .special-label {
  display: none;
}

.forty {
  width: 40%;
  float: left;
}

.sixty {
  width: 60%;
  float: left;
}

.fortyyy {
  width: 35%;
  float: left;
}

.sixtyyyyy {
  width: 63%;
  float: right;
}

.sixty .react-tel-input input.form-control {
  width: 95px;
  border: none;
  border-bottom: 1px solid #a4a4a4;
  border-radius: 0;
  margin-top: -2px !important;
  padding-left: 40px;
}

.sixty .react-tel-input .flag-dropdown {
  border: none;
  border-bottom: 1px solid #a4a4a4;
}
.mobile-view {
  display: none;
}
a.mmmm:hover {
  color: var(--buttom_color);
}
.per_border1 {
  border-bottom: 2px solid var(--secondary_btn-color);
}
.per_border_right {
  border-right: 2px solid var(--secondary_btn-color);
}
.right-side-email {
  display: -webkit-inline-box;
  margin-bottom: 5px;
  margin-left: 20px;
}

@media (max-width: 691px) {
  .zero-position {
    padding: auto;
  }
}

@media (max-width: 991px) {
  .zero-position {
    padding: auto;
  }
  .c-footer {
    width: 100%;
  }
}

.main-login {
  max-width: 700px;
  background: #fff;
  width: 100%;
  padding: 44px;
  min-height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

a.login-btn {
  border: 3px solid var(--secondary_btn-color);
  padding: 6px 27px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  border-radius: 30px;
  margin: 34px;
  background-color: var(--secondary_btn-color);
}

a.login-btn:hover {
  border-color: var(--sec_hover_color);
  background: var(--sec_hover_color);
  color: #fff;
}

.main-login-body {
  /* background: url(https://aurora-py-dev-admin.s3.ap-southeast-2.amazonaws.com/app-files/bg-image.jpeg)
    no-repeat; */
    background: url(images/bg.webp)  no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  background-size: cover;
}

.main-login h2 {
  line-height: 47px;
  color: var(--secondary_btn-color) !important;
}

button.ui.compact.fluid.disabled.button.clndr-button-today {
  display: none;
}

.auth0-lock.auth0-lock .auth0-loading-container .auth0-loading {
  position: absolute;
  left: 30%;
  margin-bottom: 10px;
  width: 26px;
  height: 35px;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.2)
    rgba(0, 0, 0, 0.2);
  opacity: 0.9;
  border-radius: 20px;
  animation: rotate 1s linear infinite;
}

.avatarprofilename.sb-avatar.sb-avatar--text {
  margin: 8px;
}

.myimage {
  margin: 30px;
}

.avatarprofilename span {
  color: var(--secondary_btn-color);
}

.image-progress-kivisaver img {
  max-width: 50%;
  margin-bottom: 40px;
  text-align: center;
}

.c-sidebar {
  overflow-y: auto;
}

@media (max-width: 576px) {
  .c-sidebar.c-sidebar-fixed {
    top: 78px;
  }
  .flex-order.col-md-8 {
    order: 2;
    display: flex;
  }
  .dlex.order-flex.col-md-4 {
    order: 1;
  }
  .order-flex-investment {
    order: 2;
  }
  .order-button-investment.col-md-3.col-sm-12.col-12 {
    order: 1;
  }
  h5.all_heading_color.myffhead.mb-2 {
    margin-top: 13px;
  }
  .order-KiwiSaver-first {
    order: 1;
  }
  .order-KiwiSaver-second {
    order: 2;
  }
  .order-KiwiSaver-Third {
    order: 3;
  }
  h5.all_heading_color.myffhead {
    margin-top: 13px;
  }
  .order_first {
    order: 1;
  }
  .Order_second {
    order: 2;
    margin-top: 13px;
  }
}

@media (max-width: 480px) {
  .auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-widget {
    width: 100% !important;
  }
  form.auth0-lock-widget {
    width: 100% !important;
  }
}

@media (min-width: 900px) {
  .createStatement {
    min-height: 26vw;
    position: relative;
  }
  .p-4.pt-0.back-opect {
    position: absolute;
    bottom: 0;
  }
}

.mmmm {
  /* background: var(--buttom_color); */
  color:var(--buttom_color);
  padding: 10px 15px;
}

#bg tbody tr:nth-child(odd) {
  background: none !important;
  /* border-bottom: 2px solid #dee2e6; */
}

#bg tbody tr:nth-child(even) {
  background: none !important;
  /* border-bottom: 2px solid #dee2e6; */
}

#bg table tr td,
table tr th {
  border: none;
}

.table th,
.table td {
  border-top: none !important;
  border-left: none !important;
}

/* .note {
  padding: 10px 0px;
} */

.note .scroll-note {
  word-break: break-word;
}

i.fas.fa-copy.iocn {
  padding-left: 50px;
}

form.payAcc textarea {
  border: 0;
  resize: none;
  overflow: hidden;
  height: 25px !important;
  overscroll-behavior: none;
}

.mmmmmm {
  padding-left: 233px;
}

.contribution {
  display: flex;
  justify-content: flex-end;
}

h5.currentvalue {
  text-indent: 20px;
}

.fund-chart.row {
  justify-content: space-between;
}

.dlex.col-md-3 {
  display: -webkit-flex !important;
  display: flex !important;
  justify-content: flex-end !important;
  padding: 0.5px;
}

.alignment {
  display: flex;
  justify-content: space-between;
}

.createStatement.invstfive {
  background-position: center;
}

/* login */

.logo.bg-color {
  background: var(--secondary_btn-color);
  padding: 25px;
}

.main-login.login {
  max-width: 450px;
  justify-content: flex-start;
  padding: 0;
  border-radius: 5px;
}

.login-heading {
  color: #fff !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  padding-top: 15px !important;
  line-height: 25px;
}

.logologin {
  max-width: 160px;
  height: auto;
}

.emailverified {
  padding: 0 20px;
}

.emailverified a {
  display: block;
  padding-top: 5px;
}

.emailId {
  font-weight: 700 !important;
  font-size: 18px !important;
  padding: 15px 0 !important;
}

a.Log_ack {
  margin-bottom: 15px;
  color: var(--secondary_btn-color);
}
a.Log_ack:hover{
  color: var(--sec_hover_color);
}

.emailverified {
  font-size: inherit;
}

/* premraj css */

.note-new-popup textarea {
  padding: 10px;
}

.note-new-popup .save {
  background-color: #dfdfe0;
}

.note-new-popup .note-footer {
  float: right;
}

.note-new-popup {
  padding-bottom: 5px !important;
}

.aboutuser .row {
  width: 100%;
  margin-top: 0px;
}

.copy_icon {
  margin-left: 12px;
}
.payment_tab tbody {
  border-left: 1px solid #e9eaed;
  border-top: 1px solid #e9eaed;
}
.InvestOption__cnt {
  padding: 0 10px;
}
.img__text {
  padding: 15px;
}
.mobile_full_width {
  width: 100%;
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 999999999;
}

.table-scroll {
  position: relative;
  max-width: 600px;
  margin: auto;
  overflow: hidden;
  border: 1px solid #000;
}
.table-wrap {
  width: 100%;
  overflow: auto;
}
.table-scroll table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  border: 1px solid #000;
  background: #fff;
  white-space: nowrap;
  vertical-align: top;
}
.table-scroll thead,
.table-scroll tfoot {
  background: #f9f9f9;
}
.clone {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
}
.clone th,
.clone td {
  visibility: hidden;
}
.clone td,
.clone th {
  border-color: transparent;
}
.clone tbody th {
  visibility: visible;
  color: red;
}
.clone .fixed-side {
  border: 1px solid #000;
  background: #eee;
  visibility: visible;
}
.clone thead,
.clone tfoot {
  background: transparent;
}

.right__content__box {
  padding: 15px;
}

.contentRight__box {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.add__adviser label {
  margin-bottom: 0;
}
.modal-body input.ant-input {
  padding-left: 0;
}
.add__adviser .ant-row.ant-form-item {
  margin-bottom: 6px;
}
.investment_dropdown {
  display: none;
}
.side-faq_mobile {
  display: none;
}

@media screen and (max-width: 991px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .c-sidebar.c-sidebar-dark.c-sidebar-show.c-sidebar-fixed.is_active {
    display: none;
  }
  .c-sidebar-backdrop.c-show.is_active {
    opacity: 0;
    display: none;
  }
  .order-button-investment,
  .order-flex-investment {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .right__content__box {
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  select.investment_dropdown {
    background-position-x: calc(100% - 20px) !important;
  }
  .side-faq_mobile .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
  }
  h4.color_bluegreen {
    font-size: 16px;
    line-height: 20px;
  }
  .align-items-center.mt-3.mb-4.row {
    margin-top: 0 !important;
  }
  .alignment_new > div button.m-2.grad_btn.d-inline-block {
    float: left;
    margin-left: 0 !important;
  }
  .alignment_new > div {
    margin-left: -15px;
    margin-right: -15px;
  }
  main.c-main {
    padding-top: 0;
  }
  main.c-main .ml-auto.d-table {
    margin: 8px 0 !important;
  }
  button.m-2.grad_btn.d-inline-block {
    float: left;
  }
  table#bg tr {
    display: flex;
  }
  table#bg th {
    padding: 7px;
    font-size: 13px;
    width: 110px;
  }
  table#bg td {
    width: calc(100% - 110px) !important;
  }
  h6.note {
    height: auto;
  }
  .main-content-container.container-fluid .align-items-center.mt-3.mb-4.row {
    margin-top: 0 !important;
  }
  .tran_head h4 {
    margin-bottom: 5px;
  }
  .arrow_size {
    max-width: 11px;
    margin-left: 3px;
    margin-top: -4px;
  }
  .tran_head.container-fluid h4.color_bluegreen {
    margin-bottom: 15px;
  }
  a button.m-2.grad_btn.d-inline-block {
    margin: 0 !important;
  }
  .width__add .ant-col.ant-form-item-control {
    max-width: 100% !important;
  }
  .width__add .fortyyy {
    width: 25%;
  }
  .width__add .sixtyyyyy {
    width: 75%;
  }
  .width_class .forty {
    width: 100%;
  }
  .width_class .sixty {
    width: 100%;
  }
  .button_width .ant-col.ant-form-item-control {
    max-width: 100% !important;
    margin-top: 8px;
  }
  .pop_width-100 .ant-col.ant-form-item-label {
    max-width: 100% !important;
    margin-top: 8px;
  }
  .pop_width-100 .ant-col.ant-form-item-control {
    max-width: 100% !important;
  }
  .pop_width-100 .ant-row.ant-form-item.date_width {
    width: 100%;
  }
  .emailveri {
    flex-wrap: wrap;
  }
  .page_advise .emailveri p {
    word-break: break-word;
  }
  .arrow_size {
    max-width: 11px !important;
    margin-left: 3px !important;
    margin-top: -2px !important;
  }
  .grad_btn,
  .grad_btn:hover {
    padding: 5px 11px !important;
    font-size: 13px !important;
  }
  /* .mobile-view{
        display: block;
    }
    .destop-view{
        display: none;
    } */

  .mobile-view_none,
  .destop-view {
    display: none;
  }

  .investment_dropdown {
    display: block;
  }
  .side-faq_mobile {
    display: block;
  }
  .side-faq_mobile select {
    width: 100% !important;
    padding: 8px 20px !important;
    background-color: #fff;
    margin-bottom: 25px !important;
  }

  .side-faq_mobile .main-content-container.pfx-4.container-fluid {
    padding: 0;
  }
  .side-faq_mobile .InvestOption__cnt {
    padding: 15px;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    margin: 10px 0;
  }
  .faq-box
    .side-faq
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded.MuiAccordionSummary-gutters.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    background-image: linear-gradient(270deg, #2a6481, #85c3c5);
    height: 48px;
    min-height: 48px !important;
  }
  .faq-box .side-faq li {
    padding: 0 !important;
    border-radius: 5px;
  }
  h4.color_bluegreen {
    font-size: 1.25rem;
    margin-top: 13px;
  }
  h6.note {
    min-height: auto;
  }
  .InvestOption__cnt {
    padding: 0;
  }
  .main-content-container.px-4.container-fluid {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .coming_content-p {
    width: 100%;
    padding: 15px 10px !important;
    display: table;
  }
  .main-login {
    padding: 35px 20px;
    width: 92%;
    min-height: auto;
  }
  a.login-btn {
    margin: 20px 0 0 0;
  }
  .right_zero p.header_text.ml-auto.mr-55 {
    margin-right: 0;
  }
  .contentRight__box {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .contentRight__box h5.all_heading_color.myffhead {
    margin-top: 0;
  }
  .menu.mr-55 {
    margin-right: 25px;
  }
}

@media screen and (max-width: 576px) {
  .menu.mr-55 {
    margin-right: 10px;
  }
}

@media screen and (max-width: 480px) {
  form.auth0-lock-widget {
    width: 300px !important;
  }
  .InvestOption__main h2 {
    font-size: 28px;
  }
  .userda.row .card-body {
    padding: 0;
  }
  .aboutuser .row dd,
  dt {
    font-size: 13px;
  }
  .main-content-container .grad_btn,
  .grad_btn:hover {
    background:  var(--buttom_color);
    border: none;
    padding: 7px 12px;
    font-size: 12px;
  }
  .main-content-container .arrow_size {
    max-width: 10px;
    margin-left: 4px;
    margin-top: -3px;
  }
  .chart_value h5 {
    font-size: 14px;
  }

  h5.all_heading_color.myffhead h5 {
    font-size: 18px;
    line-height: 25px !important;
  }
  h4.color_bluegreen {
    margin-bottom: 15px;
  }
  #exampletrans_wrapper .dataTables_length {
    width: 100%;
    margin-bottom: 10px;
  }

  .coming_content-p h6.note {
    margin-top: 20px;
    color: #fff !important;
  }
  .table td,
  .table th {
    padding: 7px;
  }
  .userda .highlevel {
    font-size: 20px;
  }
  a button.m-2.grad_btn.d-inline-block {
    float: right !important;
  }
  .chart_value.show_chart_value h5.chartbtn {
    font-size: 14px;
  }
  button.m-2.grad_btn.d-inline-block {
    float: left;
  }
  .Primaryblock.col-md-12 {
    margin-bottom: 25px;
  }
  .notes-list {
    padding-bottom: 20px;
  }
  button.submmc.btn.btn-pill {
    margin: 10px 2px;
    width: 55%;
    font-size: 12px;
  }
  button.deleteapp.btn.btn-pill {
    margin: 10px 2px;
    width: 55%;
    font-size: 12px;
  }
  .performance_section .performance_month.border-right-0 {
    padding: 14px 20px !important;
  }
  h5.all_heading_color.myffhead.mb-2 {
    font-size: 18px;
  }
  .align-items-center.mt-3.mb-3.alignment.row {
    margin-bottom: 0 !important;
  }
  .card-body {
    /* padding: 20px !important; */
  }
}

@media screen and (max-width: 450px) {
  .logo-width {
    margin-bottom: 10px !important;
  }
  .swal2-html-container > .mydataaaa {
    text-align: left !important;
    margin-bottom: 0;
  }
  table#bg th {
    width: 95px;
  }
  table#bg td {
    width: calc(100% - 95px) !important;
  }
  .notes-list {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
  }
  .add__adviser .ant-col.ant-form-item-label {
    max-width: 100% !important;
  }

  .add__adviser .ant-col.ant-form-item-control {
    max-width: 100% !important;
  }
  .add__adviser .ant-col.ant-form-item-label {
    max-width: 100% !important;
  }
  .dataTables_length {
    width: 100% !important;
    margin-bottom: 5px;
  }
  .page-link {
    padding: 7px !important;
    font-size: 13px;
  }
}
@media (max-width: 400px) {
  .menu.mr-55 {
    margin-right: 0;
  }
}

@media screen and (max-width: 320px) {
  .padding_0.col-md-9 {
    padding-right: 0px;
    padding-left: 4px;
  }
}

.page-item.active .page-link {
  color: #fff;
  background-color: #477894 !important;
  border-color: #477894 !important;
}
@media screen and (max-width: 767px) {
  .InvestOption__main .p-4.pt-0.back-opect {
    padding: 8px 15px 15px 15px !important;
  }
  select.investment_dropdown {
    margin-left: 0 !important;
  }
  .performance_section .col-6 {
    flex: 100%;
    max-width: 100%;
    /* border-right: 0; */
  }

  .performance_month.border-bottom-0 {
    border-right: 0;
  }

  .performance_section .per_border_right {
    border-right: 0;
    flex: 0 0 100%;
    max-width: 100%;
    border-bottom: 2px solid #477894 !important;
  }
}
@media screen and (max-width: 480px) {
  .performance_section .performance_month.border-right-0 {
    padding: 15px !important;
  }
  .performance_section {
    padding: 10px;
  }
  .InvestOption__cnt h5 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 991px) {
  .selfdata {
    padding-left: 20px;
    padding-right: 20px;
  }
}
input.funding {
  margin: 8px 0 !important;
}
.selfdata .row {
  padding: 0 8px !important;
}

input#expiryDate {
  padding-left: 0 !important;
}
input.form-control.form-control-sm.ml-0.my-1 {
  font-size: 14px;
  padding: 4px 12px;
}
.dataTables_wrapper label {
  align-items: center;
  margin-bottom: 0;
}
dl.row.aboutuser > .row {
  padding: 0;
}
.doubleScroll-scroll-wrapper {
  width: auto !important;
}

button.ant-btn.ant-btn-button.ant-btn-round.coffee-color {
  height: 27px !important;
}

span.uplode
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  width: 19px;
}
button.ant-btn.ant-btn-button.ant-btn-round.coffee-color {
  padding: 0px 18px;
}

.performance_section p {
  font-size: 14px;
  margin-bottom: 5px !important;
}

/* .performance_month {
    min-height: 186px !important;
} */

@media screen and (min-width: 1500px) {
  /* .performance_month {
        min-height: 140px !important;
    } */
}

@media screen and (max-width: 1199px) {
  /* .performance_month {
        min-height: 230px !important;
    } */
}

@media screen and (max-width: 480px) {
  /* .performance_month {
      min-height: 280px !important;
    } */
}

h5.chartbtn.pl-3.text-right {
  color: #1F1F1F!important;
}

.new-design-hearder {
  color: #1F1F1F!important;

}
.linkColor{
  color: var(--secondary_btn-color);
  
}
.linkColor:hover{
  color: var(--secondary_btn-color);
}
.text-size{
  font-weight: 600 !important;
}
.asend{
  color: #fff;
}
.excelcolor{
  background: #0f5238 !important;

}
.sorting{
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7XQMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 100%;
    width: 100%!important;
}